import React from "react";
import Layout from "../Layout";
import "./style.scss";
import image from "../../images/manisha-khemlani.jpg";
import sign from "../../images/mk-sign.png";

import img1 from "../../images/gallery/gallery-1.jpg";
import img2 from "../../images/gallery/gallery-2.jpg";
import img3 from "../../images/gallery/gallery-3.jpg";
import img4 from "../../images/gallery/gallery-4.jpg";
import img5 from "../../images/gallery/gallery-5.jpg";
import img6 from "../../images/gallery/gallery-6.jpg";
import img7 from "../../images/gallery/gallery-7.jpg";
import img8 from "../../images/gallery/Gallery-pic-7.jpg";
import img9 from "../../images/gallery/Gallery-pic-1.jpg";
import img10 from "../../images/gallery/Gallery-pic-2.jpg";
import img11 from "../../images/gallery/Gallery-pic-3.jpg";
import img12 from "../../images/gallery/Gallery-pic-4.jpg";
import img13 from "../../images/gallery/Gallery-pic-5.jpg";
import img14 from "../../images/gallery/Gallery-pic-6.jpg";
import img15 from "../../images/gallery/Gallery-pic-8.jpg";
import img16 from "../../images/gallery/Gallery-pic-9.jpg";
import img17 from "../../images/gallery/Gallery-pic-10.jpg";
import img18 from "../../images/gallery/Gallery-pic-11.jpg";
import img19 from "../../images/gallery/Gallery-pic-12.jpg";
import img20 from "../../images/gallery/Gallery-pic-13.jpg";
import img21 from "../../images/gallery/Gallery-pic-14.jpg";
import img22 from "../../images/gallery/Gallery-pic-15.jpg";
import img23 from "../../images/gallery/Gallery-pic-16.jpg";
import img24 from "../../images/gallery/Gallery-pic-17.jpg";

const galleryData = [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  img15,
  img16,
  img17,
  img18,
  img19,
  img20,
  img21,
  img22,
  img23,
  img24,
].reverse();
const Gallery = () => {
  return (
    <Layout>
      <div className="gallery">
        <h1>Dream Run for a Vision</h1>
        <div className="divider"></div>

        <p>
          The Chinmaya Champions of Change created history on the chilly morning
          of 15th January 2023 as they turned out in huge numbers to participate
          in the Marathon.
          <br />
          <br />
          Equally laudable are our Chinmaya family members who turned up to
          cheer the runners and support our efforts.
          <br />
          <br />
          More than a 100 Chinmaya team members and 7 corporate teams ran under
          the Chinmaya banners. Chinmaya contingent was among the 247 NGOs that
          participated in the marathon.
          <br />
          <br />
          The Chinmaya Champions of Change made the marathon come alive and
          carried our message of transformational change to those who were
          unaware of Chinmaya Mission’s social initiatives.
          <br />
          <br />
          Sharing some snippets from our journey below.
        </p>

        <div className="gallery__video">
          <iframe
            src="https://www.youtube.com/embed/XuDWGg9p7RI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>

        <div className="gallery__content">
          {/* <div className="divider"></div> */}
          <div className="gallery__container">
            {galleryData.map((item) => (
              <img className="gallery__pic" src={item}></img>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Gallery;
